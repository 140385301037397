'use strict';

angular.module('informaApp')
    .directive('infCheckbox', function () {
        return {
            restrict: 'E',
            scope: {
                label: '@',
                model: '=',
                inverted: '=',
                onClick: '&',
                onChange: '&',
                isDisabled: '='
            },
            templateUrl: 'directives/inf-checkbox/template.ptl.html',
            link: function (scope, el, attrs, controllers) {
                scope.make = function (m, event) {
                    if (scope.isDisabled) {
                        return;
                    }

                    scope.model = !m;

                    if (scope.onClick) {
                        scope.onClick();
                    }
                }

                scope.$watch('model', () => {
                    scope.onChange();
                });
            }
        }

    });
